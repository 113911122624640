import { store } from "@/store/store";
import { incidencia } from "@/shared/dtos/incidencias/incidencia";
import {
  VuexModule,
  Mutation,
  getModule,
  Module,
  Action,
} from "vuex-module-decorators";
import { ssmHttpService } from "@/shared/services/http-service";
import { API } from "@/shared/api";
import { incidenciaAllDto } from "@/shared/dtos/incidencias/incidenciaAllDto";
import { document_fileDto } from "@/shared/dtos/document_fileDto";
import { inventarioIncidenciasDto } from "@/shared/dtos/incidencias/inventarioincidenciasDto";

@Module({
  namespaced: true,
  name: "incidenciaModule",
  store,
  dynamic: true,
})
class incidenciaModule extends VuexModule {
  public incidencias: incidencia[] = [];
  public incidencia: incidencia = new incidencia();
  public incidencias_proyecto: incidenciaAllDto[] = [];
  public files: document_fileDto[] = [];
  public incidencias_inventario: inventarioIncidenciasDto[] = [];

  @Action({ commit: "onGetincidencias" })
  public async getincidencias() {
    return await ssmHttpService.get(API.incidencia);
  }

  @Action({ commit: "onGetincidencias_proyecto" })
  public async getincidencias_proyecto(id: any) {
    return await ssmHttpService.get(
      API.incidencia + "/incidencias_proyecto/" + id
    );
  }

  @Action({ commit: "onGetincidencias_proyecto" })
  public async getincidencias_tarea(id: any) {
    return await ssmHttpService.get(
      API.incidencia + "/incidencias_tareas/" + id
    );
  }

  @Action({ commit: "onGetincidencias_proyecto" })
  public async getincidencias_proyecto_act_inact({ id, act }: any) {
    return await ssmHttpService.get(
      API.incidencia + "/incidencias_proyecto/" + id + "/" + act
    );
  }

  @Action({ commit: "onGetincidencias_proyecto" })
  public async getincidencias_tarea_act_inact({ id, act }: any) {
    return await ssmHttpService.get(
      API.incidencia + "/incidencias_tareas/" + id + "/" + act
    );
  }

  @Action({ commit: "onGetincidencias_proyecto" })
  public async getincidencias_proyectos() {
    return await ssmHttpService.get(API.incidencia + "/incidencias_completo/");
  }

  @Action({ commit: "onGetincidencias_proyecto" })
  public async getincidencias_proyectosActivas() {
    return await ssmHttpService.get(API.incidencia + "/activos");
  }

  @Action({ commit: "onGetincidencias_proyecto" })
  public async getincidencias_proyectosInactivas() {
    return await ssmHttpService.get(API.incidencia + "/inactivos");
  }

  @Action({ commit: "onGetincidencia" })
  public async getincidencia(id: any) {
    return await ssmHttpService.get(API.incidencia + "/" + id);
  }

  @Action({ commit: "onGetproyectofiles" })
  public async getfiles(id: any) {
    return await ssmHttpService.get(API.incidencia + "/documents/" + id);
  }

  @Action({ commit: "onGetIncidenciasMovil" })
  public async getincidencias_movil() {
    return await ssmHttpService.get(API.incidencia + "/incidencias_movil/");
  }

  @Action({ commit: "onGetIncidenciasMovil" })
  public async getincidencias_movil_id(id: number) {
    return await ssmHttpService.get(
      API.incidencia + "/incidencias_movil/" + id
    );
  }

  @Action
  public async guardarincidencia(incidencia: incidencia) {
    /*toJson() no existe pero existe en la extends de BaseDto*/
    return await ssmHttpService.post(API.incidencia, incidencia.toJson());
  }

  @Action
  public async modificarincidencia(incidencia: incidencia) {
    return await ssmHttpService.put(
      API.incidencia + "/" + incidencia.id,
      incidencia
    );
  }

  @Action
  public async eliminarincidencia(incidencia: incidencia) {
    return await ssmHttpService.delete(
      API.incidencia + "/" + incidencia.id,
      null,
      false
    );
  }

  @Mutation
  public onGetIncidenciasMovil(res: inventarioIncidenciasDto[]) {
    this.incidencias_inventario = res;
  }

  @Action({ commit: "onGetproyectofiles" })
  public async eliminar_documento({ object, doc }: any) {
    return await ssmHttpService.put(
      API.incidencia + "/documents/" + object.id,
      doc,
      false
    );
  }

  @Mutation
  public onGetproyectofiles(res: document_fileDto[]) {
    this.files = res ? res.map((x) => new document_fileDto(x)) : [];
  }

  @Mutation
  public onGetincidencias(res: incidencia[]) {
    this.incidencias = res ? res.map((x) => new incidencia(x)) : [];
  }

  @Mutation
  public onGetincidencias_proyecto(res: incidenciaAllDto[]) {
    this.incidencias_proyecto = res
      ? res.map((x) => new incidenciaAllDto(x))
      : [];
  }

  @Mutation
  public onGetincidencia(res: incidencia) {
    this.incidencia = new incidencia(res);
  }
}
export default getModule(incidenciaModule);
