import { BaseDto } from '@/shared/dtos/base-dto';

export class incidencia extends BaseDto {
    public nombre !: string;
    public descripcion !: string;
    public fecha_inicio!:Date;
    public fecha_fin!:Date;
    public fecha_inicio_real!:Date;
    public id_tecnico !: number;
    public id_empresa !: number;
    public id_prioridad !: number;
    public id_estado !: number;
    public id_departamento !: number;
    public id_categoria !: number;
    public id_proyecto !: number;
    public id_inventario !: number;
    public id_tarea !: number;
    public fecha_sla!:Date;
    public sla_cumple!:boolean;
 } 
