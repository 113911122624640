









































































































































































































































import { enumeracion } from "@/shared/dtos/enumeracion";
import { tarea } from "@/shared/dtos/tareas/tarea";
import departamentoModule from "@/store/modules/departamento-module";
import empresaModule from "@/store/modules/empresa-module";
import enumeracionModule from "@/store/modules/enumeracion-module";
import tareaModule from "@/store/modules/tarea-module";
import usuarioModule from "@/store/modules/usuario-module";
import { UtilsString } from "@/utils/utils-string";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import incidenciaModule from "@/store/modules/incidencia-module";
import invetarioModule from "@/store/modules/invetario-module";
import { API } from "@/shared/api";
import { document_fileDto } from "@/shared/dtos/document_fileDto";
import proyectoModule from "@/store/modules/proyecto-module";
@Component({
  components: {
    DxDateBox: () => import("devextreme-vue/date-box"),
    HTMLEditor: () => import("@/components/html-editor/html-editor.vue"),
    Xbtn: () => import("@/components/buttons/x-btn.vue"),
    XBtnOperativeActiveInactive: () =>
      import("@/components/TypeView/x-btnOperativeActiveInactive.vue"),
    IncidenciasHandler: () =>
      import("@/views/proyectos/incidencias/incidenciasHandler.vue"),
    DocumentsHandler: () =>
      import("@/views/proyectos/Documentos/DocumentsHandler.vue"),
  },
  $_veeValidate: { validator: "new" },
})
export default class TareasFormulario extends Vue {
  @Prop({ default: -1 }) id_tarea!: number;
  @Prop({ default: -1 }) id_proyecto!: number;
  public select_tab = "tab-1";

  public enums_estados: enumeracion[] = [];
  public enums_tipo: enumeracion[] = [];
  public expand: boolean = true;

  created() {
    if (this.is_new()) {
      tareaModule.onGettarea(new tarea());
      proyectoModule.getusuariosProyecto(this.id_proyecto);
    } else {
      tareaModule.gettarea(this.id_tarea);
      incidenciaModule.getincidencias_tarea(this.id_tarea);
      this.get_files();
      proyectoModule.getusuariosTareaProyecto(this.id_tarea);
    }
    invetarioModule.getinvetarios();
    empresaModule.getempresas();
    usuarioModule.getusuarios();
    departamentoModule.getdepartamentos();
    /*
    6	Estados de las tareas
    7	Tipo de tareas
    */

    enumeracionModule.getenumeraciones_fromtipo(6).then((x: enumeracion[]) => {
      this.enums_estados = x;
    });
    enumeracionModule.getenumeraciones_fromtipo(7).then((x: enumeracion[]) => {
      this.enums_tipo = x;
    });
  }

  get datasource() {
    return tareaModule.tarea;
  }

  get usuarios() {
    if (
      //@ts-ignore
      usuarioModule.usuarios_proyecto_cache.id_pro ===
      Number.parseInt(this.$route.params.id)
    ) {
      //@ts-ignore
      return usuarioModule.usuarios_proyecto_cache.usuarios;
    }
    return proyectoModule.usuarios;
  }

  get empresas() {
    return empresaModule.empresas;
  }
  get departamentos() {
    return departamentoModule.departamentos;
  }

  get incidencias() {
    return incidenciaModule.incidencias_proyecto;
  }
  get inventarios() {
    return invetarioModule.invetarios.filter((x) => x.id_sub_inventario === 0);
  }

  public get files() {
    return tareaModule.files;
  }

  @Watch("select_tab")
  public wselect_tab() {
    if (this.select_tab === "tab-3") {
      this.get_files();
    }
  }

  public get_files() {
    if (this.datasource.id === null) {
      return;
    }
    return tareaModule.getfiles(this.datasource.id).catch(() => []);
  }

  public Save() {
    this.$validator.validateAll().then((allOk) => {
      if (allOk) {
        if (this.id_proyecto > 0) {
          this.datasource.id_proyecto = this.id_proyecto;
        }
        if (this.is_new()) {
          tareaModule
            .guardartarea(tareaModule.tarea)
            .then(() => this.finish_save());
        } else {
          tareaModule
            .modificartarea(tareaModule.tarea)
            .then(() => this.finish_save());
        }
      }
    });
  }

  finish_save() {
    this.$emit("close");
  }

  public is_new() {
    return Number.parseInt(UtilsString.ValueOf(this.id_tarea)) === -1;
  }

  public Geturl() {
    return API.tarea + "/documents/" + this.datasource.id;
  }

  public delete_file(file: document_fileDto) {
    tareaModule
      .eliminar_documento({
        object: this.datasource,
        doc: file,
      })
      .then(() => {
        this.get_files();
      });
  }

  public downlod_file(file: document_fileDto) {
    window.open(API.webApiBaseTareas + this.datasource.id + "/" + file.name);
  }
}
