import { incidencia } from "@/shared/dtos/incidencias/incidencia";
import { departamento } from "@/shared/dtos/departamento";
import { empresa } from "@/shared/dtos/empresa";
import { enumeracion } from "@/shared/dtos/enumeracion";
import { Usuario } from "@/shared/dtos/usuario";
import { proyecto } from "../proyecto";
import { tarea } from "../tareas/tarea";

export class incidenciaAllDto extends incidencia {
  public estado!: enumeracion;
  public prioridad!: enumeracion;
  public categoria!: enumeracion;
  public tecnico!: Usuario;
  public empresa!: empresa;
  public departamento!: departamento;
  public proyecto!: proyecto;
  public tarea!: tarea;
}
